import { extendTheme, ThemeConfig } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        bg: "#f2b705",
      },
    }),
  },
});

export default theme;
