import type { AppProps } from "next/app";
import { SigningCosmWasmProvider } from "contexts/cosmwasm";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "../util/theme";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <SigningCosmWasmProvider>
      <ChakraProvider theme={theme}>
        <Component {...pageProps} />
      </ChakraProvider>
    </SigningCosmWasmProvider>
  );
}
export default MyApp;
